import { get } from './index'

export function initUser() {
    return get('/init_user');
}
export function initFilters() {
    return get('/init_filters');
}

export function getUpdates() {
    return get('/updates');
}

export function categories() {
    return get('/categories');
}