import qs from 'qs';
import lru from 'lru-cache'

const BASE_URI = process.env.REACT_APP_API_URL;

export const API_URL = BASE_URI

const CommonHeaders = {
    'App-Platform': 1
}

const ApiCache = new lru({
    max: 100
})

export class ApiError extends Error {

    code = null;
    message = null;
    data = null;
    apiError = false;
    payment = false;

    constructor(code, message, data=null, apiError=true) {
        super(message);
        this.code = Number(code);
        this.message = message;
        this.data = data;
        this.apiError = apiError;
        if(this.code === 9991) {
            this.payment = {
                type: 'vip',
                reason: data.reason,
                choice: data && 'choice' in data ? data.choice : null,
                paymentReason: data && 'choice' in data ? data.choice.code : null,
            }
        } else if(this.code === 9992) {
            this.payment = {
                type: 'credits',
                reason: data.reason,
                paymentReason: data.reason.code
            }
        }
    }
}

function requestResolve(req) {
    return req.then(resp=>resp.json()).then(resp=>{
        if(resp.success) {
            return resp.data;
        } else if(resp.error) {
            const { code, message, data } = resp.error;
            throw new ApiError(code, message, data);
        } else {
            throw new ApiError(0, 'Bad gataway', {}, false);
        }
    })
}

function request(path, method='GET', body=null) {
    const options = {};
    if(body) {
        if(method === 'POST') {
            options.body = JSON.stringify(body)
        } else {
            path += `?${qs.stringify(body)}`;
        }
    }
    const req = fetch(`${BASE_URI}${path}`, {
        ...options,
        method,
        headers: {
            'Content-type': 'application/json',
            ...CommonHeaders
        },
        credentials: 'include'
    })
    return requestResolve(req);
}

export function post(path, body) {
    return request(path, 'POST', body);
}

export function get(path, queryParams, cache) {
    if(cache) {
        if(typeof(cache) !== 'object') {
            cache = {}
        }
        if(!('id' in cache)) {
            cache.id = JSON.stringify({ path, queryParams })
        }
        const value = ApiCache.get(cache.id)
        if(value !== undefined) {
            return Promise.resolve(value)
        }
    }
    return request(path, 'GET', queryParams).then(result=>{
        if(cache) {
            ApiCache.set(cache.id, result, cache.expire)
        }
        return result
    });
}

export function postMultipart(path, data) {
    const formData = new FormData();
    Object.keys(data).forEach(key=>{
        if(data[key] !== undefined) {
            formData.append(key, data[key]);
        }
    })
    const req = fetch(`${BASE_URI}${path}`, {
        method: 'POST',
        body: formData,
        headers: {
            'Accept': 'application/json',
            ...CommonHeaders
        },
        credentials: 'include'
    })
    return requestResolve(req)
}
