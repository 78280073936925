import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';

import app, { appHandler } from './app'
import intl from './intl'
import updates from './updates'

const reducers = combineReducers({
    intl,
    app,
    updates
});

const store = createStore(reducers, applyMiddleware(thunk))

export default store;

export function HandleAppPlans(data) {
    store.dispatch(appHandler('plans', data))
}

export function HandleAppAuth(data) {
    store.dispatch(appHandler('auth', data))
}

export function HandleLogout() {
    store.dispatch(appHandler('logout'))
}

export function ShowToast(message, type, params) {
    store.dispatch(appHandler('toast', {message, type, params}))
}

export function HandleAppError(error, resolver) {
    error.resolver = resolver;
    store.dispatch(appHandler('error', error))
}

export function HandleConfirm(message, callback, params){
    store.dispatch(appHandler('confirm', {message, callback, params}))
}