import { initUser, initFilters } from "../api/utils";
import { logout as logoutReq } from "../api/users";
import {isMobile} from "mobile-device-detect";

const ACTION_INIT = 'APP/INIT'
const ACTION_INIT_FILTERS = 'APP/INIT_FILTERS'
const ACTION_LOGOUT = 'APP/LOGOUT'
const ACTION_UPDATE_FILTERS = 'APP/ACTION_UPDATE_FILTERS'
const ACTION_APP_HANDLER = 'APP/APP_HANDLER'

const initState = {
    params: {},
    init: false,
    filtersInit: false,
    user: null,
    filters: {},
    handler: null,
}

export default function(state = initState, { type, payload }) {
    switch(type) {
        case ACTION_INIT:
            return {
                ...state,
                init: true,
                user: payload.user,
            }
        case ACTION_INIT_FILTERS:
            return {
                ...state,
                filtersInit: true,
                filters: payload.filters
            }
        case ACTION_APP_HANDLER:
            return {
                ...state,
                handler: payload
            }
        case ACTION_UPDATE_FILTERS:
            return {
                ...state,
                filters: payload
            }
        case ACTION_LOGOUT:
            return {
                ...state,
                user: null,
            }
        default:
            return state;
    }
}

export function initAppUser() {
    return async dispatch => {
        try {
            const result = await initUser();
            dispatch({
                type: ACTION_INIT,
                payload: result
            })

        } catch (e) { }
    }
}
export function initAppFilters() {
    return async dispatch => {
        try {
            const result = await initFilters();
            dispatch({
                type: ACTION_INIT_FILTERS,
                payload: result
            })

        } catch (e) { }
    }
}
export function setDefaultFilters(){
    return (dispatch, getState) => {
        const filters = getState().app.filters
        Object.keys(filters).forEach(filter => {
            filters[filter].value = null
        })
        dispatch({
            type: ACTION_UPDATE_FILTERS,
            payload: filters
        })
    }
}

export function hasActiveFilters() {
    return (dispatch, getState) => {
        const filters = getState().app.filters
        let active = false
        const paths = location.pathname.split('/').filter(path => path.length > 0);

        Object.keys(filters).forEach(filter => {
            if(filter === 'category' && filters[filter].values){
                const found = filters[filter].values.filter(row => row.dummy_page && row.dummy_page === paths[1])[0]
                if(found){
                    active = true
                }
            } else if(Array.isArray(filters[filter].value)){
                if(filters[filter].value.length > 0){
                    active = true
                }
            } else if(filters[filter].value && filters[filter].value !== '0'){
                active = true
            }
        })

        return active;
    }
}

export function hasActiveAdditionalFilters() {
    const skipFilters = ['name']
    if(!isMobile){
        skipFilters.push('location')
        skipFilters.push('condition')
    }
    return (dispatch, getState) => {
        const filters = getState().app.filters
        let active = false
        const paths = location.pathname.split('/').filter(path => path.length > 0);

        Object.keys(filters).forEach(filter => {
            if(filter === 'category' && filters[filter].values){
                const found = filters[filter].values.filter(row => row.dummy_page && row.dummy_page === paths[1])[0]
                if(found){
                    active = true
                }
            } else if(skipFilters.indexOf(filter) === -1){
                if(Array.isArray(filters[filter].value)){
                    if(filters[filter].value.length > 0){
                        active = true
                    }
                } else if(filters[filter].value && filters[filter].value !== '0'){
                    active = true
                }
            }
        })

        return active;
    }
}

export function setFilter(filter, value){
    return (dispatch, getState) => {
        const filters = getState().app.filters
        if(filters[filter]){
            filters[filter].value = value
        }
        dispatch({
            type: ACTION_UPDATE_FILTERS,
            payload: filters
        })
    }
}

export function logout() {
    return async dispatch => {
        try {
            await logoutReq();
            dispatch({
                type: ACTION_LOGOUT
            })
        } catch(e) {}
    }
}

export function appHandler(type, data) {
    return {
        type: ACTION_APP_HANDLER,
        payload: {
            type,
            data
        }
    }
}
