import React from 'react'
import { connect } from 'react-redux'
import { I18nProvider } from '@lingui/react'

class I18nLoader extends React.Component {

    static displayName = 'I18nLoader'

    state = {
        catalogs: {}
    }

    loadCatalog = async (language) => {
        const catalog = await import(`../../locales/${language}/messages.json`)

        this.setState(state => ({
            catalogs: {
                ...state.catalogs,
                [language]: catalog
            }
        }))
    }

    componentDidMount() {
        this.loadCatalog(this.props.language)
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { language } = nextProps
        const { catalogs } = nextState

        if (language !== this.props.language && !catalogs[language]) {
            this.loadCatalog(language)
            return false
        }

        return true
    }

    render() {
        const { children, language } = this.props
        const { catalogs } = this.state

        if (!catalogs[language]) return null;

        return (
            <I18nProvider language={language} catalogs={catalogs}>
                {children}
            </I18nProvider>
        )
    }
}

export default connect(({ intl: { language } }) => ({
    language
}))(I18nLoader)