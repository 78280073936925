import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './style.css'

import { isMobile } from "mobile-device-detect";

if(isMobile){
    document.querySelector('#root').classList.add('isMobile');
} else {
    document.querySelector('#root').classList.add('isDesktop');
}

const container =  document.querySelector('#root');
hydrateRoot(container, <App />);