import React from 'react';
import 'typeface-roboto';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux'
import {HelmetProvider} from "react-helmet-async";
import store from './redux'

import IntlProvider from './components/intl'

import Routes from './routes'

import moment from 'moment'
import 'moment/locale/bg'

moment.locale('bg')

class App extends React.Component {

    static displayName = 'App'

    render() {
        return (
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <Provider store={store}>
                    <HelmetProvider>
                        <IntlProvider>
                            <Routes />
                        </IntlProvider>
                    </HelmetProvider>
                </Provider>
            </GoogleOAuthProvider>
        );
    }
}

export default App;