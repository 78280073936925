import React from 'react';
import { connect } from 'react-redux'

import { BrowserRouter as Router, Route } from 'react-router-dom'

import { initAppUser, initAppFilters } from '../redux/app'

const AuthRoutesLazy = React.lazy(() => import('./routes'))

const AuthRoutes = props => (
    <React.Suspense fallback={null}>
        <AuthRoutesLazy {...props}/>
    </React.Suspense>
)

class App extends React.PureComponent {

    static displayName = 'App'

    componentWillMount() {
        this.props.initUser();
        this.props.initFilters();
    }

    render() {
        return (
            <Router>
                <Route component={AuthRoutes}/>
            </Router>
        )
    }
}

export default (connect(({ app }) => ({
    init: app.init
}), dispatch => ({
    initUser: () => dispatch(initAppUser()),
    initFilters: () => dispatch(initAppFilters())
}))(App))

