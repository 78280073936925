const ACTION_CHAGNE_LANG = 'INTL/CHANGE_LANGUAGE'

const initState = {
    language: 'bg'
}

export default function (state = initState, { type, payload }) {
    switch (type) {
        case ACTION_CHAGNE_LANG:
            return { 
                ...state, 
                language: payload.language
            }
        default:
            return state;
    }
}

export function changeLanguage(language) {
    return async dispatch=>{
        dispatch({
            type: ACTION_CHAGNE_LANG,
            payload: {
                language
            }
        })
    }
}