import merge from "deepmerge";

const ACTION_UPDATES_POLLING = 'UPDATES/ACTION_UPDATES_POLLING'
const ACTION_UPDATES_SET = 'UPDATES/ACTION_UPDATES_SET'

const initState = {
    updates: {}
}

export default function (state = initState, { type, payload }) {
    switch (type) {
        case ACTION_UPDATES_POLLING:
            return {
                ...state,
                updates: payload.updates
            }
        case ACTION_UPDATES_SET:
            return {
                ...state,
                updates:merge(state.updates, payload)
            }
        default:
            return state;
    }
}

export function updates(updates) {
    return async dispatch => {
        dispatch({
            type: ACTION_UPDATES_POLLING,
            payload: {
                updates
            }
        })
    }
}

export function setUpdate(data) {
    return async dispatch => {
        dispatch({
            type: ACTION_UPDATES_SET,
            payload: data
        })
    }
}