import {get, post} from './index'

export function login({ email, password, token }) {
    return post('/auth/login',  { email, password, token })
}

export function loginGoogle(token) {
    return post('/auth/login/google', {
        token
    })
}

export function loginFacebook(accessToken) {
    return post('/auth/login/facebook', {
        access_token: accessToken
    })
}

export function register({ name, email, password, agree, token }) {
    return post('/auth/register', {
        name: name,
        email: email,
        password: password,
        agree: agree,
        token: token
    })
}

export function logout() {
    return post('/auth/logout')
}

export function forgotPassword(email) {
    return post('/auth/password_recovery', {
        email
    })
}

export function loadUser(id){
    return get(`/auth/load_user/${id}`);
}